import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { BotModuleCloneForm_botModule } from '../__generated__/BotModuleCloneForm_botModule';
import { BotModuleCloneForm_companies } from '../__generated__/BotModuleCloneForm_companies';
import { BotModuleCloneForm_CloneBotModuleMutation, BotModuleCloneForm_CloneBotModuleMutationVariables } from '../__generated__/BotModuleCloneForm_CloneBotModuleMutation';
import { Button, Appearance } from './Button';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

const BotModuleCloneSchema = yup.object({
  name: yup.string().required().label("Name"),
  companyId: yup.string().label("Company"),
  language: yup.string().oneOf(window.locales.split(',')).nullable().label("Language")
})

export interface BotModuleValues {
  name: string;
  companyId: string;
  language: string;
}

export interface BotModuleCloneFormProps {
  botModule: BotModuleCloneForm_botModule;
  companies: BotModuleCloneForm_companies[];
  translateOnly: boolean;
  onCancel: () => void;
  onSubmitted?: (id:string) => void;
}

export const BotModuleCloneForm: VFCwF<BotModuleCloneFormProps> = ({
  botModule,
  companies,
  translateOnly,
  onCancel,
  onSubmitted
}) => {
  const { t, i18n } = useTranslation();
  const initialMutationErrors: string[] = []
  const initialMutationWarnings: string[] = []
  const [mutationErrors, setMutationErrors] = React.useState(initialMutationErrors)
  const [mutationWarnings, setMutationWarnings] = React.useState(initialMutationWarnings)
  const [mutationLink, setMutationLink] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const defaultValues: DefaultValues<BotModuleValues> = {
    name: `${t('copy_of')} ${botModule.name}`,
    companyId: botModule.companyId,
    language: botModule?.language || 'nl',
  };
  const { register, handleSubmit, formState } = useForm<BotModuleValues>({ defaultValues, resolver: yupResolver(BotModuleCloneSchema) })

  const [cloneButModule] = useMutation<BotModuleCloneForm_CloneBotModuleMutation, BotModuleCloneForm_CloneBotModuleMutationVariables>(
    gql`
      mutation BotModuleCloneForm_CloneBotModuleMutation($input: CloneBotModuleMutationInput!) {
        cloneBotModule(input: $input) {
          botModule {
            id
            companyId
          }
          errors
        }
      }
    `
  )

  const onSubmit: SubmitHandler<BotModuleValues> = async (values) => {
   setIsSubmitting(true)
   let {data} = await cloneButModule({
      variables: { input: { id: botModule.id, ...values } }
    })

    setIsSubmitting(false)
    if (data?.cloneBotModule?.errors && data?.cloneBotModule.errors.length > 0){
      setMutationErrors(data?.cloneBotModule?.errors)
    } else {
      if (onSubmitted) {
        if (botModule.companyId != data?.cloneBotModule?.botModule?.companyId) {
          const link = `/${data?.cloneBotModule?.botModule?.companyId}/bot_modules/${data?.cloneBotModule?.botModule?.id}`
          setMutationWarnings([t('other_company')])
          setMutationLink(link);
        }
        else {
          onSubmitted(data!.cloneBotModule!.botModule.id)
        }
      }
    }
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
        <SelectField label={t('language')} {...register('language')} errors={formState.errors} >
          {window.locales.split(',').map(x => (
            <option key={x} value={x}>{t('locale_' + x)}</option>
          ))}
        </SelectField>
        {translateOnly == false &&
          <SelectField label={t('company')} {...register('companyId')} errors={formState.errors} >
            {companies.map(company => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </SelectField>
        }

        <div className="mt-4 bottom-10 px-5 py-4 bg-yellow-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-yellow-500 text-white drop-shadow-lg font-extrabold">i</span>
            {t('translation_warnings')}
          </div>
        </div>
      </div>
      { mutationErrors && mutationErrors.length > 0 &&
        <div className="mt-4 bottom-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </div>
        </div>
      }
      { mutationWarnings && mutationWarnings.length > 0 &&
        <div className="mt-4 bottom-10 px-5 py-4 bg-yellow-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-yellow-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationWarnings.join(', ')}
            {mutationLink &&
              <a className='underline' href={mutationLink}>{t('other_company_link')}</a>
            }
          </div>
        </div>
      }
      { isSubmitting ? (
        <p>{t('loading')}</p>
        ) : (
        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button type="submit" disabled={formState.isSubmitting}>
              {t('save')}
            </Button>
          </div>
        </div>
        )
      }
    </form>
  )
}

BotModuleCloneForm.fragments = {
  botModule: gql`
    fragment BotModuleCloneForm_botModule on BotModule {
      id
      name
      language
      companyId
    }
  `,
  companies: gql`
    fragment BotModuleCloneForm_companies on Company {
      id
      name
      language
    }
  `,
}
