import React, {useState} from 'react';
import { DefaultValues, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { gql } from '@apollo/client';
import { DepartmentForm_department } from '../__generated__/DepartmentForm_department';
import { Button, Appearance } from './Button';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { TextareaField } from './textarea-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField } from './select-field';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";
import { DepartmentBotModulesUpdateRouteQuery_departmentBotModule_department } from '../__generated__/DepartmentBotModulesUpdateRouteQuery';

const DepartmentBotModuleSchema = yup.object({
  sentAtOffsetDays: yup.number().required().label("Start offset"),
  sendAtHour: yup.number().required().label("Send at hour"),
  startDate: yup.string().nullable().label("Start date"),
  endDate: yup.string().nullable().label("End date"),
  startTime: yup.number().nullable().label("Start time"),
  sendReminderDays: yup.number().nullable().label("Send reminder"),
  sendReminderRepeat: yup.number().nullable().label("Send reminder repeat"),
  sendReminderTime: yup.number().nullable().label("Send reminder time"),
  endTime: yup.number().nullable().label("End time")
})

export interface DepartmentBotModuleValues {
  sentAtOffsetDays: number | null;
  sendAtHour: number | null;
  startDate: string | null;
  endDate: string | null;
  startTime: number | null
  sendReminderDays: number | null
  sendReminderRepeat: number | 0
  sendReminderTime: number | null
  endTime: number | null
}

export interface DepartmentBotModuleForm {
  departmentBotModule?: {
    id: string,
    sentAtOffsetDays: number | null,
    sendAtHour: number | null,
    startDate: string | null,
    endDate: string | null,
    startTime: number | null,
    sendReminderDays: number | null,
    sendReminderRepeat: number,
    sendReminderTime: number | null,
    endTime: number | null,
    department: DepartmentBotModulesUpdateRouteQuery_departmentBotModule_department | null
  }
  onCancel: () => void;
  onSubmit: SubmitHandler<DepartmentBotModuleValues>;
}

export const DepartmentBotModuleForm: React.FunctionComponent<DepartmentBotModuleForm> = ({ departmentBotModule, onCancel, onSubmit } ) => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0!
  const dd = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${yyyy}-${mm}-${dd}`;

  const defaultValues: DefaultValues<DepartmentBotModuleValues> = {
    sentAtOffsetDays: departmentBotModule?.sentAtOffsetDays,
    sendAtHour: departmentBotModule?.sendAtHour,
    startDate: departmentBotModule?.startDate || formattedDate,
    endDate: departmentBotModule?.endDate,
    startTime: departmentBotModule?.startTime || 9,
    sendReminderDays: departmentBotModule?.sendReminderDays,
    sendReminderRepeat: departmentBotModule?.sendReminderRepeat || 1,
    sendReminderTime: departmentBotModule?.sendReminderTime,
    endTime: departmentBotModule?.endTime || 23
  };
  const { register, watch, handleSubmit, formState } = useForm<DepartmentBotModuleValues>({defaultValues, resolver: yupResolver(DepartmentBotModuleSchema)})
  const [addReminder, showAddReminder] = React.useState(Boolean(departmentBotModule?.sendReminderDays))

  const sendAtOffsetDaysValue = watch(["sentAtOffsetDays"]);
  const showHours = sendAtOffsetDaysValue[0] != null && sendAtOffsetDaysValue[0] != 0;
  const { t, i18n } = useTranslation();

  return (
    <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
      {(departmentBotModule?.department?.kind == 'available' || departmentBotModule?.department?.desiredKind == 'available_desired') && (
        <>
          <div>
            <div>
              <label className=" block text-sm font-medium text-gray-700">{t('start')}</label>
              <div className="">
                <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"date"} {...register('startDate')}/>
              </div>
            </div>

            <div className='mt-2'>
              <label className="block text-sm font-medium text-gray-700">{t('startTime')}</label>
              <div className="">
                <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"number"} {...register('startTime')}/>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('end')}</label>
              <div className="">
                <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"date"} {...register('endDate')}/>
              </div>
            </div>
            <div className='mt-2'>
              <label className="block text-sm font-medium text-gray-700">{t('endTime')}</label>
              <div className="">
                <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"number"} {...register('endTime')}/>
              </div>
            </div>
            {!addReminder &&
              <div className="mt-4">
                <Button type="button" appearance={Appearance.Secondary} onClick={() => showAddReminder(true)}>
                  {t('add_reminder')}
                </Button>
              </div>
            }
            {addReminder &&
              <>
                <h2 className='mt-4'>{t('send_reminder')}</h2>
                <div className='mt-2'>
                  <label className="block text-sm font-medium text-gray-700">{t('reminder_time')}</label>
                  <div className="">
                    <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"number"} {...register('sendReminderTime')}/>
                  </div>
                </div>
                <div className='mt-2'>
                  <label className="block text-sm font-medium text-gray-700">{t('send_reminder_days')}</label>
                  <div className="">
                    <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"number"} {...register('sendReminderDays')}/>
                  </div>
                </div>
                <div className='mt-2'>
                  <label className="block text-sm font-medium text-gray-700">{t('send_reminder_repeat')}</label>
                  <div className="">
                    <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" type={"number"} {...register('sendReminderRepeat')}/>
                  </div>
                </div>
                <div className="rounded-md col-span-6 px-5 py-4 bg-orange-500 bg-white mt-4">
                  <div className="flex items-start text-sm text-white">
                    <span className="mr-4 inline-block px-3 py-1 rounded-full bg-orange-500 text-white drop-shadow-lg font-extrabold">i</span>
                    {t('automated_reminder_warning')}
                  </div>
                </div>
              </>
            }
          </div>
        </>)}
      {(departmentBotModule?.department?.kind == 'scheduled' || departmentBotModule?.department?.desiredKind == 'scheduled_desired') && (
        <>
          <div className="space-y-6">
            <div>
              {t('days_after_user_added')}
            </div>
            <TextInputField type="number" label={t('number_of_days')} {...register('sentAtOffsetDays')} errors={formState.errors} />
          </div>
          {showHours &&
            <div className="space-y-3">
              <div>
                {t('hour_user_sent')}
              </div>
              <TextInputField type="number" label={t('hours')} {...register('sendAtHour')} errors={formState.errors}/>
            </div>
          }
        </>
      )}

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default DepartmentBotModuleForm
