import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { CompanyDetailsForm_UpdateCompanyMutation, CompanyDetailsForm_UpdateCompanyMutationVariables } from '../__generated__/CompanyDetailsForm_UpdateCompanyMutation';
import { CompanyDetailsForm_company } from '../__generated__/CompanyDetailsForm_company';
import { Button } from './Button';
import { FileInputField, FileInputPreview } from './FileInputField';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import useDirectUploadMutation from '../hooks/direct-upload-mutation';
import { VFCwF } from '../types';
import { ImageInputField, imageTypeTest, imageSizeTest } from './ImageInputField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";
import { BooleanInputField } from './boolean-input-field';
import { useState } from 'react';
import {Notification} from "./Notification";

const CompanyDetailsSchema = yup.object({
  name: yup.string().required().label("Name"),
  language: yup.string().oneOf(['en', 'nl']).required().label("Language"),
  email: yup.string().required().label("Email"),
  avatar: yup.object({
    blobId: yup.mixed().test(imageTypeTest).test(imageSizeTest).label("Avatar"),
  }),
  profile: yup.object({
    blobId: yup.mixed().test(imageTypeTest).test(imageSizeTest).label("Profile"),
  }),
  contactPerson: yup.object({
    name: yup.string().label("Name"),
    phone: yup.string().label("Phone")
  })
})

export interface CompanyDetailsValues {
  name: string
  shortCode: string | null
  allowWeb: boolean
  allowWhatsapp: boolean
  usesLicenses: boolean
  restrictedShop: boolean
  language: string
  email: string
  avatarUrl: string | null
  avatar: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  profileUrl: string | null
  profile: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  companyLogoUrl: string | null
  companyLogo: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  contactPerson: {
    name: string | null
    phone: string | null
  },

  emailDeliveryMethod: string,
  smtpAddress: string,
  smtpFrom: string,
  smtpDomain: string,
  smtpPassword: string,
  smtpPort: string,
  smtpUserName: string
}

export interface CompanyDetailsFormProps {
  company: CompanyDetailsForm_company
}

export const CompanyDetailsForm: VFCwF<CompanyDetailsFormProps> = ({ company }) => {
  const { t, i18n } = useTranslation();
    const [notification, setNotification] = useState('')

  const defaultValues: DefaultValues<CompanyDetailsValues> = {
    name: company.name,
    shortCode: company.shortCode,
    allowWeb: company.allowWeb,
    usesLicenses: company.usesLicenses,
    restrictedShop: company.restrictedShop,
    allowWhatsapp: company.allowWhatsapp,
    email: company.email ?? undefined,
    language: company.language || 'nl',
    avatar: {
      blobId: company?.avatarUrl || null,
      _destroy: false
    },
    profile: {
      blobId: company?.profileUrl || null,
      _destroy: false
    },
    companyLogo: {
      blobId: company?.companyLogoUrl || null,
      _destroy: false
    },
    contactPerson: {
      name: company.contactPerson?.name,
      phone: company.contactPerson?.phone
    },
    emailDeliveryMethod: company.emailDeliveryMethod,
    smtpAddress: company.smtpAddress,
    smtpFrom: company.smtpFrom,
    smtpDomain: company.smtpDomain,
    smtpPassword: company.smtpPassword,
    smtpPort: company.smtpPort,
    smtpUserName: company.smtpUserName
  }
  const { register, handleSubmit, formState, setValue } = useForm<CompanyDetailsValues>({ defaultValues, resolver: yupResolver(CompanyDetailsSchema) })
  const directUpload = useDirectUploadMutation()

  const [updateCompany] = useMutation<CompanyDetailsForm_UpdateCompanyMutation, CompanyDetailsForm_UpdateCompanyMutationVariables>(
    gql`
      mutation CompanyDetailsForm_UpdateCompanyMutation($input: UpdateCompanyMutationInput!) {
        updateCompany(input: $input) {
          company {
            id
          }
          errors
        }
      }
    `
  )

  const onSubmit: SubmitHandler<CompanyDetailsValues> = async (values) => {
    let [avatarBlobId, profileBlobId, companyLogoBlobId] = await Promise.all([
      values?.avatar.blobId instanceof FileList
        ? directUpload(values.avatar.blobId![0])
        : null,
      values?.profile.blobId instanceof FileList
        ? await directUpload(values.profile.blobId![0])
        : null,
        values?.companyLogo.blobId instanceof FileList
          ? await directUpload(values.companyLogo.blobId![0])
          : null,
    ])

    const { data } = await updateCompany({
      variables: {
        input: {
          id: company.id,
          ...values,
          avatar: { _destroy: values.avatar._destroy, blobId: avatarBlobId },
          profile: { _destroy: values.profile._destroy, blobId: profileBlobId },
          companyLogo: { _destroy: values.companyLogo._destroy, blobId: companyLogoBlobId }
        }
      }
    });

    if (data?.updateCompany?.errors?.length > 0) {
      setNotification(data!.updateCompany!.errors.join(', '));
      window.setTimeout(()=> setNotification(""), 8000);
    } else {
      setNotification('ok');
      window.setTimeout(()=> setNotification(""), 1000);
    }
  }

  const [src, setSrc] = React.useState<any>(company.companyLogoUrl);

  const onchangeCompanyPreview = (src: File | null) => {

    if (src != null){
      const reader = new FileReader();
      reader.addEventListener('load', ()=>{
        setSrc(reader.result)
      })
      reader.readAsDataURL(src)
    } else {
      setSrc(null)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 mt-8">
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-4">
              <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
            </div>
            <div className="col-span-4">
              <TextInputField label={t('short_code')}{...register('shortCode')} errors={formState.errors} />
            </div>
            <div className="col-span-2">
              <SelectField label="Language" {...register('language')} errors={formState.errors} >
                <option value="en">{t('english')}</option>
                <option value="nl">{t('nederland')}</option>
              </SelectField>
            </div>
            <div className="col-span-6">
              <TextInputField label={t('email_support')} type="email" {...register('email')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <BooleanInputField label={t('allow_whatsapp')} {...register('allowWhatsapp')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <BooleanInputField label={t('uses_licenses')} {...register('usesLicenses')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <BooleanInputField label={t('restricted_shop')} {...register('restrictedShop')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <BooleanInputField label={t('allow_web')} {...register('allowWeb')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <div className="flex">
                <div className="grow">
                  <ImageInputField
                    label={t('company_logo')}
                    preview={company.companyLogoUrl}
                    onRemove={() => {setValue('companyLogo', {blobId: null, _destroy: true}, { shouldValidate: true })}}
                    errors={formState.errors}
                    {...register('companyLogo.blobId')}
                    onChangeSrc={onchangeCompanyPreview}
                  />
                </div>
                { src &&
                  <div className="image-previews">
                    <label className="block text-sm font-medium text-gray-700">Previews</label>
                    <div className="image-previews-previews">
                      <div className='image-preview-mail'>
                        <FileInputPreview src={src} onRemove={() => {}}></FileInputPreview>
                        <p>mail</p>
                      </div>
                      <div className='image-preview-onboarding'>
                        <FileInputPreview src={src} onRemove={() => {}}></FileInputPreview>
                        <p>Onboarding</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="col-span-3">
              <TextInputField label={t('contact_person_name')} {...register('contactPerson.name')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('contact_person_phone')} {...register('contactPerson.phone')} errors={formState.errors} />
            </div>
          </div>
        </div>
        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>

      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <TextInputField label={t('email_delivery_method')} {...register('emailDeliveryMethod')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <TextInputField label={t('smtp_address')} {...register('smtpAddress')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <TextInputField label={t('smtp_from')} {...register('smtpFrom')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('smtp_domain')} {...register('smtpDomain')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('smpt_port')} {...register('smtpPort')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <TextInputField label={t('smtp_user_name')} {...register('smtpUserName')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <TextInputField label={t('smtp_password')} {...register('smtpPassword')} errors={formState.errors} />
            </div>
          </div>
        </div>
        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
      {notification && notification == "ok" &&
         <Notification error={false} text={t('updated')}></Notification>
      }
      {notification && notification != "ok" &&
         <Notification error={true} text={notification}></Notification>
      }
    </form>
  )
}

CompanyDetailsForm.fragments = {
  company: gql`
    fragment CompanyDetailsForm_company on Company {
      id
      name
      shortCode
      allowWeb
      allowWhatsapp
      usesLicenses
      restrictedShop
      language
      email
      profileUrl
      avatarUrl
      companyLogoUrl

      contactPerson {
        name
        phone
      }

      emailDeliveryMethod
      smtpAddress
      smtpFrom
      smtpDomain
      smtpPassword
      smtpPort
      smtpUserName
    }
  `
}
