import * as React from 'react'
import {Link, LinkProps, useNavigate} from 'react-router-dom'
import { gql } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/solid'
import cx from 'clsx'
import { Topbar_user } from '../__generated__/Topbar_user'
import { VFCwF } from '../types'
import {Button} from "./Button";
import {useTranslation} from "react-i18next";

export interface TopbarNavLinkProps extends LinkProps {}

export const TopbarNavLink: React.FC<TopbarNavLinkProps> = ({ children, className, style, ...props }) => {
  return (
    <div className={className} style={style}>
      <Link className="text-gray-900 text-sm font-medium" {...props}>
        {children}
      </Link>
    </div>
  )
}

export interface TopbarProps {
  navLinks?: React.ReactNode
  menuLinks?: React.ReactNode
  user?: Topbar_user
}

export const Topbar: VFCwF<TopbarProps> = ({ navLinks, menuLinks, user }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let onLogoutClick = () => {
    window.location = '/logout';
  }

  return (
    <div className="max-w-6xl mx-auto px-8">
      <div className="flex justify-between h-16">
        <div className="flex px-0">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img className="w-16" src="/elialogo.png" alt="Workflow" />
            </Link>
          </div>
          {navLinks && (
            <nav aria-label="Global" className="ml-6 flex items-center">
              <ul className="flex items-center space-x-4">
                {navLinks}
              </ul>
            </nav>
          )}
        </div>

        <div className="ml-4 flex items-center">
          <div className="flex-shrink-0 flex items-center">
            {user && user!.role == 'superadmin' &&
              <>
                <Button onClick={() => navigate({
                  pathname: '/companies/new',
                }, {state: {backgroundLocation: location}})}>
                  {t('create_company')}
                </Button>
              </>
            }
          </div>
          {/* <button type="button" className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
            </svg>
          </button> */}

          <Menu as="div" className="ml-4 relative flex-shrink-0">
            <div>
              <Menu.Button className="bg-white rounded-full flex text-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span className="sr-only">{t('open_user_menu')}</span>
                <UserCircleIcon className="h-8 w-8" />
              </Menu.Button>
            </div>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div className="px-4 py-3">
                  {user ? (
                    <React.Fragment>
                      <p className="text-xs text-gray-500">{t('signed_in_as')}</p>
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {user.email}
                      </p>
                    </React.Fragment>
                  ) : (
                    <p>{t('loading')}</p>
                  )}
                </div>

                {menuLinks && (
                  <div className="py-1">
                    {menuLinks}
                  </div>
                )}

                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button className={cx(active ? 'text-blue-600' : 'text-gray-700', 'group flex items-center w-full text-left px-4 py-2 text-sm')} onClick={onLogoutClick}>
                        <svg aria-hidden="true" width="20" height="20" fill="none" className="flex-none mr-3 text-gray-400 group-hover:text-blue-600">
                          <path d="M10.25 3.75H9A6.25 6.25 0 002.75 10v0A6.25 6.25 0 009 16.25h1.25M10.75 10h6.5M14.75 12.25l2.5-2.25-2.5-2.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>

                        {t('sign_out')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

Topbar.fragments = {
  user: gql`
    fragment Topbar_user on User {
      email
      role
    }
  `
}
