import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { SlideOver } from '../components/SlideOver';
import {DepartmentBotModulesUpdateRouteQuery} from "../__generated__/DepartmentBotModulesUpdateRouteQuery";
import DepartmentBotModuleForm, {DepartmentBotModuleValues} from "../components/DepartmentBotModuleForm";
import {
  DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutation,
  DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutationVariables
} from "../__generated__/DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutation";
import {useTranslation} from "react-i18next";

interface BotModuleDepartmentsUpdateRouteProps {
  companyId: string;
}

const BotModuleDepartmentsUpdateRoute: React.FC<BotModuleDepartmentsUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()

  const { t, i18n } = useTranslation();
  const { departmentId } = useParams<'departmentId'>();
  const { botModuleId } = useParams<'botModuleId'>();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = React.useState(initialMutationErrors)

  const { data, loading, error } = useQuery<DepartmentBotModulesUpdateRouteQuery>(gql`
    query DepartmentBotModulesUpdateRouteQuery($departmentId: ID!, $botModuleId: ID!) {
      departmentBotModule(departmentId: $departmentId, botModuleId: $botModuleId) {
        id
        position
        sentAtOffsetDays
        sendAtHour
        endDate
        endTime
        startDate
        startTime
        sendReminderDays
        sendReminderTime
        department {
          id
          kind
          desiredKind
        }
      }
    }
  `, {
    variables: {
      departmentId: departmentId as string,
      botModuleId: botModuleId as string
    }
  });

  const [updateDepartmentBotModule] = useMutation<DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutation, DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutationVariables>(gql`
    mutation DepartmentBotModulesUpdateRoute_UpdateDepartmentBotModuleMutation($input: UpdateDepartmentBotModuleMutationInput!) {
      updateDepartmentBotModuleMutation(input: $input) {
        departmentBotModule {
          id
          sentAtOffsetDays
          sendAtHour
          endDate
          endTime
          startDate
          startTime
          sendReminderDays
          sendReminderTime
          department {
            id
            kind
          }
        }
        errors
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/departments/${departmentId}`)
  }

  async function onSubmit(values: DepartmentBotModuleValues) {
    let {data} = await updateDepartmentBotModule({
      variables: {
        input: {
          botModuleId: botModuleId as string,
          departmentId: departmentId as string,
          sentAtOffsetDays: values.sentAtOffsetDays,
          sendAtHour: values.sendAtHour,
          startDate: values.startDate,
          startTime: values.startTime,
          sendReminderDays: values.sendReminderDays,
          sendReminderTime: values.sendReminderTime,
          endDate: values.endDate,
          endTime: values.endTime
        }
      }
    })

    if (data?.updateDepartmentBotModuleMutation?.errors && data?.updateDepartmentBotModuleMutation?.errors.length > 0){
      setMutationErrors(data?.updateDepartmentBotModuleMutation?.errors)
    } else {
      navigate(`/${companyId}/departments/${departmentId}`)
    }
  }
  return (
    <SlideOver title={t('edit_department')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (
        <div>
          <DepartmentBotModuleForm
            departmentBotModule={data!.departmentBotModule!}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
          {mutationErrors && mutationErrors.length > 0 &&
            <div className="mt-4 bottom-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
              <div className="text-sm text-white">
                <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
                {mutationErrors.join(', ')}
              </div>
            </div>
          }
        </div>
      )}
    </SlideOver>
  )
}

export default BotModuleDepartmentsUpdateRoute;
