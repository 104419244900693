import React, {useState} from 'react';
import { Link, useParams, useNavigate, createSearchParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { SubmitHandler } from 'react-hook-form';
import cx from 'clsx'
import { BotUserForm, BotUserValues } from '../components/BotUserForm';
import { VFCwF } from '../types';
import LocalTime from '../components/LocalTime';
import {loadBotUser, makeUpdateBotUserMutation} from "../queries/BotUsersQueryHandler";
import {
  BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage
} from "../__generated__/BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage";
import {useTranslation} from "react-i18next";
import { BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department, BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department_botModules, BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department_botModules_quizAttempts } from '../__generated__/BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department';
import { BotUsersDetailsRouteQuery_botUser } from '../__generated__/BotUsersDetailsRouteQuery';
import { BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage } from '../__generated__/BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage';

//Message to Sent
interface ToSentMessageTableProps {
  toSendMessages: BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage[]
  className?: string
  companyId: string
}

interface ToSentMessageTableRowProps {
  toSendMessage: BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage
  companyId: string
}

const ToSentMessageTable: React.VFC<ToSentMessageTableProps> = ({ toSendMessages, companyId, className }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={cx('overflow-x-auto border border-gray-200 rounded-lg', className)}>
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr className='border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'>
            <th className='pl-4 py-3' colSpan={7}>
              To send messages
            </th>
          </tr>
          <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3" >department</th>
            <th scope="col" className="px-8 py-3" >bot module</th>
            <th scope="col" className="px-8 py-3" >start department</th>
            <th scope="col" className="px-8 py-3" >offset dagen</th>
            <th scope="col" className="px-8 py-3" >offset uren</th>
            <th scope="col" className="px-8 py-3" >laatst verstuurde module</th>
            <th scope="col" className="px-8 py-3" >wordt verstuurd?</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100">
        {toSendMessages.map(toSendMessage => (
          <ToSentMessageTableRow key={toSendMessage.departmentName + toSendMessage.botModuleName} toSendMessage={toSendMessage} companyId={companyId}/>
        ))}

        {toSendMessages.length === 0 && (
          <tr>
            <td className="px-8 py-3 text-sm text-gray-500" colSpan={6}>
              {t('none_available')}
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export const ToSentMessageTableRow: VFCwF<ToSentMessageTableRowProps> = ({ toSendMessage, companyId }) => {
  return (
    <tr className="px-8 py-3 text-sm text-left font-medium text-gray-500">
      <td className="px-8 py-3 text-xs">{toSendMessage.departmentName}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.botModuleName}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.botUserDepartmentStartDate}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.offsetDays}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.offsetHours}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.lastSendDepartmentBotModuleName}</td>
      <td className="px-8 py-3 text-xs">{toSendMessage.schedule == true ? 'ja' : 'nee'}</td>
    </tr>
  )
}

ToSentMessageTableRow.fragments = {
  toSendMessage: gql`
    fragment BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage on ToSendMessage {
      departmentName
      botModuleName
      botUserDepartmentStartDate
      offsetDays
      offsetHours
      availableDate
      schedule
      lastSendDepartmentBotModuleName
    }
  `
}

// QuizAttempts
interface QuizAttemptTableProps {
  departments: BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department[]
  className?: string
  companyId: string
  botUser: BotUsersDetailsRouteQuery_botUser
}

interface QuizAttemptTableRowProps {
  botUser: BotUsersDetailsRouteQuery_botUser
  department: BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department
  botModule: BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department_botModules
  companyId: string
  key: string
}

export const QuizAttemptTableRow: VFCwF<QuizAttemptTableRowProps> = ({department, botUser, botModule, companyId }) => {
  const { t, i18n } = useTranslation();

  const botUserQuizModules = botModule.quizAttempts.filter(x => x.botUser.id == botUser.id);
  const totalCorrect = botUserQuizModules.length > 0 ? botUserQuizModules.map(x => x.correct).reduce((partialSum, a) => partialSum += a) : 0
  const total = botUserQuizModules.length > 0 ? botUserQuizModules.map(x => x.total).reduce((partialSum, a) => partialSum += a) : 0

  const botSessions = department.botModules.filter(x => x.id == botModule.id).map(x => x.botSessions).flat().filter(x => x?.botUser?.id == botUser.id)

  let startDate = null;
  let endDate = null;
  let finished = false;
  let started = true;

  if (botSessions != null && botSessions.length > 0){
    startDate = botSessions[0].startDate
    endDate = botSessions[0].endDate
    finished = botSessions[0].finished
  } else {
    started = false;
  }

  return (
    <tr className="px-8 py-3 text-sm text-left font-medium text-gray-500">
      <th scope="row" className="px-8 whitespace-nowrap text-gray-900" >
        {botModule &&
          <Link className="hover:text-gray-400" to={`/${companyId}/bot_modules/${botModule.id}`} >{botModule.name}</Link>
        }
      </th>
      <th className="px-8 py-3">
        {startDate}
      </th>
      <th className="px-8 py-3">
        {endDate}
      </th>
      <th className="px-8 py-3">
        {finished ? t('finished') : started ? t('ongoing') : t('not_started')}
      </th>
      <th className="px-8 py-3">
        { total == 0 ? '-' : `${totalCorrect}/${total}`}
      </th>
    </tr>
  )
}

QuizAttemptTableRow.fragments = {
  department: gql`
    fragment BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department on Department {
      id
      name
      departmentProgressScore(botUserIds: $botUserIds)
      botModules {
        id
        name
        botSessions {
          id
          startDate
          endDate
          finished
          botUser {
            id
          }
        }
        quizAttempts {
          id
          botUser {
            id
          }
          createdAt
          closedAt
          correct
          total
          passed
          card {
            id
            title
          }
        }
      }
    }
  `
}

const QuizAttemptTable: React.VFC<QuizAttemptTableProps> = ({ botUser, departments, companyId, className }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      { departments.map(department => {
        const departmentModules = 0.0 + department.botModules.length;
        const botUserBotSessions = 0.0 + department.botModules.map(x => x.botSessions).flat().filter(x => x?.botUser?.id == botUser.id && x.finished == true).length;

        const progress = department.departmentProgressScore || 0;

        return(
          <div key={department.id} className={cx('overflow-x-auto border border-gray-200 rounded-lg', className)}>
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  <td scope="col" className="pl-4 py-3" colSpan={4}>{department.name}</td>
                  <td className="px-8 py-3">{progress}%</td>
                </tr>
                <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  <th scope="col" className="px-8 py-3" >{t('bot_module_model_name')}</th>
                  <th scope="col" className="px-8 py-3" >{t('start')}</th>
                  <th scope="col" className="px-8 py-3" >{t('end')}</th>
                  <th scope="col" className="px-8 py-3" >{t('status')}</th>
                  <th scope="col" className="px-8 py-3" >{t('score')} </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {department.botModules.map(botModule => (
                  // <span key={botModule.id}>{JSON.stringify(botModule)}</span>
                  <QuizAttemptTableRow department={department} botUser={botUser} botModule={botModule} key={botModule.id + "_" + department.id} companyId={companyId}/>
                ))}
              </tbody>
            </table>
          </div>
        )
        })
      }
    </>
  )
}

// SentMessage
interface SentMessageTableProps {
  sentMessages: BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage[]
  className?: string
  companyId: string
}

interface SentMessageTableRowProps {
  sentMessage: BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage
  companyId: string
}

const SentMessageTable: React.VFC<SentMessageTableProps> = ({ sentMessages, companyId, className }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={cx('overflow-x-auto border border-gray-200 rounded-lg', className)}>
      <table className="min-w-full">
        <thead className="bg-gray-50">
        <tr className='border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'>
            <th className='pl-4 py-3' colSpan={6}>
              Send messages
            </th>
          </tr>
        <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
          <th scope="col" className="pl-8 py-3" >{t('created_at')}</th>
          <th scope="col" className="pl-8 py-3" >{t('kind')}</th>
          <th scope="col" className="pl-8 py-3" >{t('stack')}</th>
          <th scope="col" className="px-8 py-3" >{t('message')} </th>
          <th scope="col" className="px-8 py-3" >{t('body')} </th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100">
        {sentMessages.map(sentMessage => (
          <SentMessageTableRow key={sentMessage.id} sentMessage={sentMessage} companyId={companyId}/>
        ))}

        {sentMessages.length === 0 && (
          <tr>
            <td className="px-8 py-3 text-sm text-gray-500" colSpan={6}>
              {t('none_available')}
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export const SentMessageTableRow: VFCwF<SentMessageTableRowProps> = ({ sentMessage, companyId }) => {
  return (
    <tr className="px-8 py-3 text-sm text-left font-medium text-gray-500">
      <td scope="row" className="px-8 py-3 whitespace-nowrap text-xs" >
        <LocalTime dateTime={sentMessage.createdAt} format="{YYYY}-{MM}-{DD} {HH}:{mm}:{ss}" />
        <br/>
        {sentMessage.direction}
        <br/>
        {sentMessage.messageType}
        <br/>
      </td>
      <td>
        {(sentMessage.kind == 'unknown' || sentMessage.kind == 'status_message') && (
          <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">status message</span>
        )}
        {sentMessage.kind == 'schedule_message' && (
          <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-700 dark:text-blue-300">stack message</span>
        )}
        {sentMessage.kind == 'incoming_bot_user_message' && (
          <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">incoming</span>
        )}
        {sentMessage.kind == 'incoming_whatsapp_message' && (
          <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">whatsapp</span>
        )}
        {sentMessage.kind == 'outgoing_message' && (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">outgoing</span>
        )}
      </td>
      <td scope="row" className="px-8 py-3 text-xs" >
        {sentMessage.retryCount}
      </td>
      <td scope="row" className="px-8 py-3 text-xs" >
        {sentMessage.message}
      </td>
      <td scope="row" className="px-8 py-3 text-gray-400 text-xs" style={{wordBreak: 'break-all', width: '400px'}} >
        {sentMessage.body}
      </td>
    </tr>
  )
}

SentMessageTableRow.fragments = {
  sentMessage: gql`
    fragment BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage on SentMessage {
      id
      retryCount
      message
      createdAt
      body
      messageType
      kind
      direction
      botSessionId
    }
  `
}

interface BotUsersDetailsRouteProps {
  companyId: string
  userRole: string | null
}

const BotUsersDetailsRoute: React.VFC<BotUsersDetailsRouteProps> = ({ companyId , userRole}) => {
	const navigate = useNavigate()
  const { botUserId } = useParams<'botUserId'>();
  const { data, loading, error } = loadBotUser(botUserId)
  const { t, i18n } = useTranslation();
  const [updateBotUser] = makeUpdateBotUserMutation()
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const onSubmit: SubmitHandler<BotUserValues> = async (values) => {
    if(window.confirm(t('are_you_sure_bot_user_department'))) {
      let { data } = await updateBotUser({ variables: { input: { companyId, id: botUserId as string, ...values } } })
      const botUser = data!.updateBotUser!.botUser

      setMutationErrors(data!.updateBotUser!.errors)

      if (data!.updateBotUser!.errors.length == 0) {
        navigate(botUser
          ? `/${companyId}/bot_users?department=${botUser!.departmentId}&highlight=${botUser!.id}`
          : `/${companyId}/bot_users`
        )
      }
    }
  }

  if (error){
    debugger
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/bot_users`} className="hover:text-gray-400">
              {t('bot_users')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>

            <h1 className="text-gray-900 truncate" style={{margin: '0px'}}>
              {t('details_of')} {data?.botUser ? `${data.botUser.firstName} ${data.botUser.lastName}` : '...'}
            </h1>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (!data || !data.botUser) ? (
          <p>{t('not_found')}</p>
        ) : (
          <div className="grid grid-cols-3 gap-6">
            <BotUserForm className="col-span-3" useSteps={false} companyId={companyId} botUser={data.botUser} departments={data.botUser.company.departments} onSubmit={onSubmit}/>
            {mutationErrors && mutationErrors.length > 0 &&
              <div className="bottom-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
                <div className="text-sm text-white">
                  <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
                  {mutationErrors.join(', ')}
                </div>
              </div>
            }

            <QuizAttemptTable className="col-span-3" botUser={data.botUser} departments={data.botUser.departments!} companyId={companyId}/>
            {userRole == 'superadmin' &&
              <>
                <ToSentMessageTable className="col-span-3" toSendMessages={data.botUser.toSendMessages!} companyId={companyId}/>
                <SentMessageTable className="col-span-3" sentMessages={data.botUser.sentMessages!} companyId={companyId}/>
              </>
            }
          </div>
        )}
      </main>
    </div>
  )
}

export default BotUsersDetailsRoute;
