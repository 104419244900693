import * as React from 'react';
import {useEffect, useState} from "react";


export interface NotificationProps {
  text: String
  error: boolean
}

export const Notification: React.VFC<NotificationProps> = ({ text, error }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 1500)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  if (!show){
    return null;
  }

  if (error){
    return (
      <div className="bottom-12 right-4 z-10000 fixed bg-red-100 rounded-b text-teal-900 px-3 py-3 shadow-md mb-4"
          role="alert">
        <div className="flex items-center">

          <div>
            <p className="text-sm m-0">{text}</p>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="bottom-12 right-4 z-10000 fixed bg-green-100 rounded-b text-teal-900 px-3 py-3 shadow-md mb-4"
          role="alert">
        <div className="flex items-center">
          <div
            className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Check icon</span>
          </div>
          <div>
            {/*<p className="font-bold">Gegevens aangepast</p>*/}
            <p className="text-sm m-0">{text}</p>
          </div>
        </div>
      </div>
    )
  }
}
