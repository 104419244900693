import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BotUsersOverviewRoute_DeleteBotUserMutation, BotUsersOverviewRoute_DeleteBotUserMutationVariables } from '../__generated__/BotUsersOverviewRoute_DeleteBotUserMutation';
import { BotUsersOverviewRouteQuery,
  BotUsersOverviewRouteQueryVariables} from '../__generated__/BotUsersOverviewRouteQuery';
import {BotUserTable, BotUserTableRow} from '../routes/BotUsersOverviewRoute'
import {QuizAttemptTableRow, SentMessageTableRow, ToSentMessageTableRow} from '../routes/BotUsersDetailsRoute'
import {BotUsersDetailsRouteQuery} from "../__generated__/BotUsersDetailsRouteQuery";
import {BotUserForm} from "../components/BotUserForm";
import {
  BotUsersDetailsRoute_UpdateBotUserMutation,
  BotUsersDetailsRoute_UpdateBotUserMutationVariables
} from "../__generated__/BotUsersDetailsRoute_UpdateBotUserMutation";

export const loadBotUsers = (companyId: string, searchParams: any, departmentId: any) => {
  const data = useQuery<BotUsersOverviewRouteQuery, BotUsersOverviewRouteQueryVariables>(gql`
    query BotUsersOverviewRouteQuery($companyId: ID!, $departmentIds: [ID!], $after: String, $before: String, $pageCount: Int) {
      company(id: $companyId) {
        id

        departments {
          id
          name
        }

        ...BotUsersOverviewRoute_BotUserTableQuery_botUsers
      }
    }

    ${BotUserTable.fragments.botUsers}
  `, {
    variables: {
      companyId,
      departmentIds: departmentId ? [departmentId] : null,
      after: searchParams.get('after'),
      before: searchParams.get('before'),
      pageCount: searchParams.get('pageCount') ? parseInt(searchParams.get('pageCount') ) : 10,
    }, fetchPolicy: 'network-only'
  });

  return data;
}

export const loadBotUser = (botUserId: any) => {

  const data = useQuery<BotUsersDetailsRouteQuery>(gql`
    query BotUsersDetailsRouteQuery($id: ID!, $botUserIds: [ID!]) {
      botUser(id: $id) {
        id
        companyId
        ...BotUserForm_botUser

        departments {
          ...BotUsersDetailsOverviewRoute_QuizAttemptTableRow_department
        }

        sentMessages {
          ...BotUsersDetailsOverviewRoute_SentMessageTableRow_sentMessage
        }
        toSendMessages {
          ...BotUsersDetailsOverviewRoute_SentMessageTableRow_toSendMessage
        }
      }
    }

    ${QuizAttemptTableRow.fragments.department}
    ${BotUserForm.fragments.botUser}
    ${SentMessageTableRow.fragments.sentMessage}
    ${ToSentMessageTableRow.fragments.toSendMessage}
  `, {
    variables: {
      id: botUserId as string,
      botUserIds: [botUserId]
    }
  });

  return data;
}

export const makeDeleteBotUserMutation = () => {
  const result = useMutation<BotUsersOverviewRoute_DeleteBotUserMutation, BotUsersOverviewRoute_DeleteBotUserMutationVariables>(gql`
    mutation BotUsersOverviewRoute_DeleteBotUserMutation($input: DeleteBotUserMutationInput!) {
      deleteBotUser(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'BotUsersOverviewRouteQuery',
      'BotUsersOverviewRouteDepartmentsQuery'
    ]
  })

  return result
}

export const makeUpdateBotUserMutation = () => {
  const result = useMutation<BotUsersDetailsRoute_UpdateBotUserMutation, BotUsersDetailsRoute_UpdateBotUserMutationVariables>(
    gql`
      mutation BotUsersDetailsRoute_UpdateBotUserMutation($input: UpdateBotUserMutationInput!) {
        updateBotUser(input: $input) {
          botUser {
            id
            firstName
            lastName
            email
            mobile
            departmentId
            companyId
            testUser
            botUserType
          }
          errors
        }
      }
    `, {
      awaitRefetchQueries: true,
      refetchQueries: [
        'BotUsersOverviewRouteQuery',
        'BotUsersOverviewRouteDepartmentsQuery'
      ]
    }
  )

  return result;
}

export const loadCreateBotUser = (companyId: any) => {
  const data = useQuery(gql`
    query BotUsersCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        departments {
          id
          name
          kind
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  return data;
}

export const makeCreateBotUserMutation = () => {
  const data = useMutation(gql`
    mutation BotUsersCreateRoute_CreateBotUserMutation($input: CreateBotUserMutationInput!) {
      createBotUser(input: $input) {
        botUser {
          id
          departmentId
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'BotUsersOverviewRouteQuery',
      'BotUsersOverviewRouteDepartmentsQuery'
    ]
  })

  return data;
}
