import * as React from 'react';
import { gql } from '@apollo/client';
import {Link, useLocation} from 'react-router-dom';
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'clsx'

import {
  DepartmentsDetailsRouteQuery_department as Department,
  DepartmentsDetailsRouteQuery_department_departmentBotModules as DepartmentBotModule,
  DepartmentsDetailsRouteQuery_department_departmentBotModules_botModule as BotModule
} from '../__generated__/DepartmentsDetailsRouteQuery';
import { FCwF } from '../types';
import {Menu, MenuList, MenuLink, MenuItem, MenuButton} from "@reach/menu-button";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";

export interface BotModuleListItemProps {
  departmentBotModule: DepartmentBotModule;
  department: Department;
  position: number;
  onDelete: (id: string) => void;
}

export const BotModuleListItem: FCwF<BotModuleListItemProps> = ({ departmentBotModule, department, position, onDelete }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: departmentBotModule.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      className="relative"
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="relative flex bg-white rounded-md shadow-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 hover:cursor-grab active:cursor-grabbing">
        <div className="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-500 text-xs font-medium rounded-l-md">
          {position +1}
        </div>
        <div className="flex-1 items-center justify-center p-3">
          {departmentBotModule.botModule.name}
        </div>
        <div className="flex-0 items-center justify-center p-3">
          { department.templateEditable && (
            <Menu>
              <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <span className="sr-only">{t('options')}</span>
                <DotsVerticalIcon className="h-5 w-5" />
              </MenuButton>
              <MenuList>
                <MenuLink as={Link} to={`/${department.companyId}/bot_modules/${departmentBotModule.botModule.id}`}>
                  {t('view')}
                </MenuLink>
                {(department.kind == 'scheduled' || department.desiredKind == 'scheduled_desired' || department.kind == 'available') &&
                  <MenuLink as={Link}
                            to={`/${department.companyId}/departments/${department.id}/bot_modules/${departmentBotModule.botModule.id}/edit`}
                            state={{backgroundLocation: location}}>
                    {t('edit')}
                  </MenuLink>
                }
                <MenuItem onSelect={() => onDelete(departmentBotModule.id)}>
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </div>
      </div>
      {(department.kind == 'scheduled' || department.desiredKind == 'scheduled_desired'|| department.kind == 'available') &&
        <div>
          { ((department.kind == 'scheduled' || department.desiredKind == 'scheduled_desired') && departmentBotModule.sentAtOffsetDays && departmentBotModule.sentAtOffsetDays > 0) ? (
            <div className="p-2 pl-4 text-sm flex items-center">
              <span className="material-symbols-outlined">
                schedule
              </span>
              <span className="pl-2">+ {departmentBotModule.sentAtOffsetDays} days</span>
              <span className="pl-2">at {departmentBotModule.sendAtHour}:00</span>
            </div>
          ) : department.kind == 'available' ? (
            <div className="p-2 pl-4 text-sm flex items-center">
              <span className="material-symbols-outlined">
                schedule
              </span>
              {departmentBotModule.startDate &&
              <>
                <span className="pl-1">{t('from')} {departmentBotModule.startDate.split('-').reverse().join('-')} om {departmentBotModule.startTime}:00</span>
                </>
              }
              {departmentBotModule.endDate &&
                <span className="pl-1">{t('t/m')} {departmentBotModule.endDate.split('-').reverse().join('-')} om {departmentBotModule.endTime}:00</span>
              }
            </div>
          ) : (
              <div className="p-2 pl-4 text-sm flex items-center">
                    <span className="material-symbols-outlined">
                      schedule
                    </span>
                <span className="pl-2">{t('immediately')}</span>
              </div>
            )}
        </div>
      }
    </li>
  )
}

BotModuleListItem.fragments = {
  botModule: gql`
    fragment BotModule on DepartmentBotModule {
      botModule {
        id
        name
      }
    }
  `
}

export interface BotModuleListProps {
  department: Department;
  showAll: boolean;
  onDelete: (id: string) => void;
  onSorted: (departmentBotModules: DepartmentBotModule[]) => void;
}

export const BotModuleList: FCwF<BotModuleListProps> = ({ department, showAll, onSorted, onDelete }) => {
  const [items, setItems] = React.useState<DepartmentBotModule[]>(department.departmentBotModules ?? []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  React.useEffect(() => {
    setItems(department.departmentBotModules ?? []);
  }, [department])

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over!.id) {
      const oldIndex = items.findIndex((departmentBotModule) => departmentBotModule.id === active.id)
      const newIndex = items.findIndex((departmentBotModule) => departmentBotModule.id === over!.id)
      const sorted = arrayMove(items, oldIndex, newIndex)

      setItems(sorted)
      onSorted(sorted)
    }
  }

  const sortedItems = [...items].sort((a,b) => ((a.sentAtOffsetDays || 0) < (b.sentAtOffsetDays || 0)) ? -1 : ((a.sentAtOffsetDays || 0) > (b.sentAtOffsetDays || 0)) ? 1 : 0);
  const availableItems = [...items].filter(x => {
    return (x.endDate == null || new Date(x.endDate) > new Date()) &&
    (x.startDate == null || new Date(x.startDate) < new Date())
  }).sort((a,b)=> a.startDate == null ? -1 : b.startDate == null ? 1 : new Date(a.startDate) < new Date(b.startDate) ? -1 : 1);

  const allAvailableItems = [...items].sort((a,b)=> a.startDate == null ? -1 : b.startDate == null ? 1 : new Date(a.startDate) < new Date(b.startDate) ? -1 : 1);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      {
        (department.kind == 'scheduled' || department.desiredKind == 'scheduled_desired') ? (
          <ul className={cx('space-y-2')}>
            {sortedItems.map((item, index) => (
              <BotModuleListItem key={item.id} departmentBotModule={item} department={department} position={index}
                                 onDelete={onDelete}/>
            ))}
          </ul>
          ) : (
            (department.kind == 'available' || department.desiredKind == 'available_desired') ? (
              <div style={{paddingTop: '32px'}}>
                <SortableContext items={items}>
                  {showAll ? (
                    <ul className={cx('space-y-2')}>
                      {allAvailableItems.map((item, index) => (
                        <BotModuleListItem key={item.id} departmentBotModule={item} department={department} position={index}
                                          onDelete={onDelete}/>
                      ))}
                    </ul>
                    ):(
                      <ul className={cx('space-y-2')}>
                        {availableItems.map((item, index) => (
                          <BotModuleListItem key={item.id} departmentBotModule={item} department={department} position={index}
                                            onDelete={onDelete}/>
                        ))}
                      </ul>
                    )}
                </SortableContext>
              </div>
            ): (
              <>
                <SortableContext items={items}>
                  <ul className={cx('space-y-2')}>
                    {[...items].map((item, index) => (
                      <BotModuleListItem key={item.id} departmentBotModule={item} department={department} position={index}
                                        onDelete={onDelete}/>
                    ))}
                  </ul>
                </SortableContext>
              </>
            )
          )
      }
    </DndContext>
  )
}

BotModuleList.fragments = {
  departmentBotModules: gql`
    fragment DepartmentBotModules on Department {
      departmentBotModules {
        id
        position
        sentAtOffsetDays
        sendAtHour
        startDate
        endDate
        startTime
        endTime
        ...BotModule
      }
    }
    ${BotModuleListItem.fragments.botModule}
  `
}
